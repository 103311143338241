import {Injectable, OnDestroy} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {LOAD_DOC_TYPES, LOAD_SEARCH_INVOICES} from "../../store/actions/invoices.actions";
import {DocTypesRequest} from "../api/doc.types.request.model";
import {phx} from "../../store/@types/models";
import {InvoicesRequest} from "../api/invoices.request.model";
import Invoices = phx.portal.models.Invoices;


@Injectable({
  providedIn: 'root'
})
export class InvoicesService implements OnDestroy {
  private ngUnsubscribe$ = new Subject<boolean>();
  public docTypes$: Observable<any> | null = null;
  private loadedDoctypesForIdf: any[] = [];
  private docTypes: Map<number, any> = new Map<number, any>();

  constructor(public store: Store<{ invoice: Invoices }>) {
  }


  getUserDocumentTypes(request: DocTypesRequest): Observable<any> | null {
    if (!this.docTypes$ || !this.keyExists(request)) {
      this.store.dispatch(LOAD_DOC_TYPES({payload: request}));
      this.docTypes$ = this.store.select('invoice').pipe(takeUntil(this.ngUnsubscribe$),
        map((response: any) => {
          if (response.docTypes !== undefined && response.docTypes.get(request.idf) !== undefined) {
            this.docTypes.set(request.idf, response.docTypes.get(request.idf));
          }
          return this.docTypes;
        }));
    }
    return this.docTypes$;
  }


  private keyExists(docRequest: DocTypesRequest): boolean {
    return this.docTypes ? this.docTypes.has(docRequest.idf) : false;
  }


  searchDocuments(invoicesRequest: InvoicesRequest) {
    this.store.dispatch(LOAD_SEARCH_INVOICES({payload: invoicesRequest}));
    return this.store.select('invoice').pipe(takeUntil(this.ngUnsubscribe$), map((response: any) => {
      return response.items;
    }));
  }

  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy(): void {
    this.docTypes$ = null;
    this.ngUnsubscribe$.next(true);
    this.ngUnsubscribe$.unsubscribe();
  }
}
