import {Component, OnInit, OnDestroy, OnChanges, SimpleChanges} from '@angular/core';
import {TrackTraceRequest} from "../../core/track-trace/api/track.trace.request";
import {TrackTraceRestService} from "../../core/track-trace/services/track.trace.rest.service";
import {DocRequest} from "../../core/invoices/api/doc.request.model";
import {InvoicesRestService} from "../../core/invoices/services/invoices.rest.service";
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {UserService} from "../../core/user/services/user.service";

@Component({
  selector: 'app-track-trace',
  templateUrl: './track-trace.component.html',
  styleUrl: './track-trace.component.scss'
})
export class TrackTraceComponent implements OnInit, OnDestroy, OnChanges {
  tours: any[] = [];
  loading = true;
  private ngUnsubscribe$ = new Subject<boolean>();
  delivered: any[] = [];
  filter: string = '';
  filtered: any[] = [];
  now: Date = new Date();
  status: string = 'delivery';
  current: any = null;
  intervals: any[] = [];
  invoiceFilter = '';
  docs: any[] = [];
  currentIdf = '9999910';

  constructor(private trackTraceRestService: TrackTraceRestService,
              private invoicesRestService: InvoicesRestService,
              private userService: UserService) {
    this.userService.getMainIdfObservable()?.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((response: any) => {
      if (response.return_object != undefined) {
        this.currentIdf=response.return_object;
      }
    });
  }

  ngOnInit() {
    this.loadAllTours(this.now);
  }

  ngOnChanges(change: SimpleChanges) {
    console.log('changing');
    console.log(change);
  }

  downloadInvoice(document: any) {
    let docRequest = new DocRequest({
      docDate: this.now.toISOString().split('T')[0],
      docNr: document,
      docType: 'INVOICE',
      idf: this.currentIdf,
      vz: 21
    });
    this.invoicesRestService.getDocument(docRequest);
  }

  delay(tour: any): string {
    const delay = tour.delay_in_minutes;

    if (delay !== null) {
      if (delay > 30)
        return 'text-danger';
      else if (delay >= 10)
        return 'text-alert';
      else
        return 'text-success';
    }
    return 'no';
  }

  ngOnDestroy(): void {
    this.intervals.forEach((inter: any, i: number) => {
      clearInterval(inter)
    });
    this.intervals = [];
    this.ngUnsubscribe$.next(false);
    this.ngUnsubscribe$.complete();
  }

  filterProducts(filter: string, products: any[]) {

    if (filter !== '') {
      return products.filter((prod: any) => prod.article_code.indexOf(this.filter) === 0 || prod.article_name.toLowerCase().indexOf(this.filter.toLowerCase()) > -1);
    }
    return products;
  }

  setFilter($event: any, invoice: any, pack: any[]) {
    this.filter = $event.target.value;
    this.filtered[invoice] = this.filterProducts(this.filter, this.getProductsForInvoice(pack, invoice));
  }

  getDocumentNos(pack: any, tour_id?: any) {
    let docs: any[] = [];
    pack.forEach((tote: any) => {
      this.filtered[tote.delivery_advice_no] = this.filterProducts(this.filter, this.getProductsForInvoice(pack, tote.delivery_advice_no));
      let pzns: any[] = [];
      this.filtered[tote.delivery_advice_no].forEach((prod: any) => {
        if (pzns.indexOf(prod.article_code) === -1) {
          pzns.push(prod.article_code);
        }
      });

      if (docs.indexOf(tote.delivery_advice_no === -1)) {
        docs.push(tote.delivery_advice_no);

      }
      if (!tour_id) {
        return;
      }

      if (!this.docs[tour_id]) {
        this.docs[tour_id] = [];
      }

      if (!this.docs[tour_id].find((detail: any) => detail.doc === tote.delivery_advice_no)) {
        this.docs[tour_id].push({doc: tote.delivery_advice_no, products: pzns});
      }
    });

    return docs;
  }

  loadTours(event: any) {
    this.status = event.value;
    if (event.value === "yesterday") {
      const d = new Date(this.now);
      const date = new Date(d.setDate(this.now.getDate() - 1));
      this.loadAllTours(date);
    } else {
      this.loadAllTours(this.now)
    }
  }

  private loadAllTours(date: Date) {
    const cet = new Intl.DateTimeFormat('de-DE', {timeStyle: 'short',timeZone: 'Europe/Berlin'}).format(new Date());
    const check = this.now.toISOString().split('T')[0]+'T'+cet;

    this.trackTraceRestService.getTours(new TrackTraceRequest(21, this.currentIdf, date.toISOString().split('T')[0], true))
    .pipe(takeUntil(this.ngUnsubscribe$))
    .subscribe((tours: any) => {
        const trs = tours.return_object.tour_information.tour;

        if (date === this.now) {

          const turs = this.adjustNight(trs.filter((del: any) => del.delivered_at === null && del.planned_time_of_arrival >= check));
          this.current = turs[0];
          setTimeout(() => {
            const inter = setInterval(() => {
              this.intervals.forEach((inter: any, i: number) => {
                clearInterval(inter)
              });
              this.intervals = [];
              this.loadTourStatus(this.current.tour_id, true);
            }, 1000 * 60 * 5);
            this.intervals.push(inter);
          }, 1000)


          if (turs.length > 1) {
            this.tours = turs.slice(1);
          }
          this.delivered = this.adjustNight(trs.filter((del: any) => del.delivered_at !== null || del.planned_time_of_arrival < check));
        } else {
          this.intervals.forEach((inter: any, i: number) => {
            clearInterval(inter)
          });
          this.delivered = this.adjustNight(trs);
        }

        this.docs = [];

        trs.forEach((pack: any) => {
          this.getDocumentNos(pack.package, pack.tour_id);
        })

        this.loading = false;
      },
      (error: any) => {
        this.loading = false;
      })
  }

  private loadTourStatus(tour_id: any, current = false) {
    this.trackTraceRestService.getTours(new TrackTraceRequest(21, this.currentIdf, this.now.toISOString().split('T')[0], false, tour_id))
    .pipe(takeUntil(this.ngUnsubscribe$))
    .subscribe((tours: any) => {
        const trs = tours.return_object.tour_information.tour;

        if (current) {
          this.current['expected_time_of_arrival_max'] = trs[0]['expected_time_of_arrival_max']
          this.current['expected_time_of_arrival_min'] = trs[0]['expected_time_of_arrival_min']
          this.current['delivered_at'] = trs[0]['delivered_at']
          this.current['delay_in_minutes'] = trs[0]['delay_in_minutes']
          this.current['delay_information'] = trs[0]['delay_information']

          if (trs[0]['delivered_at'] !== null) {
            setTimeout(() => {
              this.loadAllTours(this.now);
              this.intervals.forEach((inter: any, i: number) => {
                clearInterval(inter)
              })
            }, 60 * 30000);
            this.intervals = [];
          }
        } else {
          const i = this.tours.findIndex((tour: any) => tour.tour_id === tour_id);

          if (i > -1) {
            this.tours[i]['expected_time_of_arrival_max'] = trs[0]['expected_time_of_arrival_max']
            this.tours[i]['expected_time_of_arrival_min'] = trs[0]['expected_time_of_arrival_min']
            this.tours[i]['delivered_at'] = trs[0]['delivered_at']
            this.tours[i]['delay_in_minutes'] = trs[0]['delay_in_minutes']
            this.tours[i]['delay_information'] = trs[0]['delay_information']

          }
        }

        this.loading = false;
      },
      (error: any) => {
        this.loading = false;
      })
  }

  private adjustNight(tours: any[]) {
    const day = tours.filter((dy: any) => dy.tour_id.substring(0, 2) < 24);
    const night = tours.filter((nt: any) => nt.tour_id.substring(0, 2) >= 24);
    return [...night, ...day];
  }

  getProductsForInvoice(pack: any, invoice: any) {
    let products: any[] = [];

    pack.forEach((tote: any) => {
      if (tote['delivery_advice_no'] === invoice) {
        tote['line_details'].forEach((line: any) => {
          products.push(line);
        })
      }
    })
    return products;
  }

  getTour(tour_id: any) {
    if (tour_id < 24) {
      return this.now.toLocaleDateString() + ' ' + tour_id.substring(0, 2) + ':' + tour_id.substring(2, 4);
    } else {
      const d = new Date(this.now);
      let diff = Math.floor(tour_id.substring(0, 2) / 24);
      let date = d;

      if (this.status === 'yesterday') {
        date = new Date(d.setDate(this.now.getDate() - 1));
      }

      if (diff) {
        date = new Date(d.setDate(date.getDate() - diff));
      }

      return Intl.DateTimeFormat('de-DE').format(date) + ' ' + ('00' + (tour_id.substring(0, 2) - diff * 24)).slice(-2) + ':' + tour_id.substring(2, 4) + ' / ' + tour_id.substring(4)
    }
  }

  tourPercent(tour: any) {
    if (tour.delivered_at !== null) {
      return 100;
    }

    if (tour.expected_time_of_arrival_max !== null) {
      return 66;
    }

    return 33;
  }

  setInvoiceFilter($event: any, pack: any) {
    const pzn = $event.target.value;

    this.invoiceFilter = pzn;
    this.getDocumentNos(pack.package, pack.tour_id);

    if (this.invoiceFilter !== '') {
      this.docs[pack.tour_id] = this.docs[pack.tour_id].filter((doc: any) => this.findPzn(doc.products));
    }
  }

  private findPzn(pzns: any[]) {
    return pzns.find((pzn: any) => pzn.indexOf(this.invoiceFilter) === 0) !== undefined;
  }

  setIdf($event: any) {
    this.currentIdf = $event;
    this.loadAllTours(this.now);
  }

  getMinutes(delayMin: any) {
    return (delayMin < 0) ? 0 : delayMin;
  }
}
