import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CmsComponent} from './cms.component';
import {RouterModule, Routes} from '@angular/router';
import {FormsComponent} from './forms/forms.component';
import {ContentComponent} from './content/content.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatRadioModule} from '@angular/material/radio';
import {SafeHtmlModule} from "../safehtml/safehtml.module";
// import {SuccessModule} from '../success/success.module';
// import {NotificationModule} from '../notification/notification.module';

export const routes: Routes = [
    {
        path: '', component: CmsComponent, pathMatch: 'full'
    },
];

@NgModule({
    declarations: [
        FormsComponent,
        ContentComponent,
        CmsComponent
    ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    SafeHtmlModule,
    // SuccessModule,
    // NotificationModule
  ]
})
export class CmsModule {
}
