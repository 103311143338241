<h2 mat-dialog-title *ngIf="data.type != 'notice'">{{data.title}}</h2>
<h1 mat-dialog-title *ngIf="data.type === 'notice'" class="text-center notice-title my-m" color="primary">{{data.title}}</h1>
<div class="text-center icn"  *ngIf="data.icon">
  <span class="material-icons" color="primary">{{data.icon}}</span>
</div>
<mat-dialog-content><div [innerHTML]="data.content"></div></mat-dialog-content>
<mat-dialog-actions class="mx-m">


  <button mat-flat-button mat-dialog-close *ngIf="data.type != 'alert' && data.type != 'notice'" >Schliessen</button>

  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-flat-button [mat-dialog-close]="true" color="primary" *ngIf="data.type === 'confirm'">{{data.ok}}</button>
</mat-dialog-actions>
