import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FooterComponent} from "./footer.component";
import {MatDividerModule} from '@angular/material/divider';
import {RouterModule} from '@angular/router';


@NgModule({
  declarations: [FooterComponent],
  imports: [
    CommonModule,
    MatDividerModule,
    RouterModule
  ],
  exports: [FooterComponent]
})
export class FooterModule {
}
