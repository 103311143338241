<header class="header container d-flex aic jcsb">
  <div class="d-flex aic">
    <a routerLink="/dashboard">
      <img src="assets/images/hageda-stumpf-klein-logo.svg" alt="Hageda Stumpf klein logo">
    </a>
    <button mat-flat-button class="ml-m" routerLink="/belege" *ngIf="canAccess(['BULK_BILL'])">BELEGE</button>
    <button mat-flat-button routerLink="/umsatz" *ngIf="canAccess(['MY_SALES'])">UMSATZ</button>
    <button mat-flat-button routerLink="/nachlieferung" *ngIf="canAccess(['NACH'])">NACHLIEFERUNG</button>
    <button mat-flat-button routerLink="/vorbestellung/grippe2024" *ngIf="canAccess(['ORDERS'])">VORBESTELLUNG GRIPPE 2024</button>
    <button mat-flat-button routerLink="/kontaktformular" *ngIf="canAccess(['CONTACT_PERSON'])">KONTAKT</button>
    <button mat-flat-button  mat-link-button color="primary"  routerLink="/admin/admin-dashboard" *ngIf="isAdmin()">ADMINISTRATION
    </button>
  </div>
  <div class="d-flex aic">
    <button mat-flat-button (click)="goToSubusers()" *ngIf="canAccess(['GROUP_ACCOUNT_OWNER'])"><span
      class="material-icons">supervisor_account</span></button>
    <button mat-flat-button class="text-uppercase" routerLink="/stammdaten" *ngIf="canAccess(['GROUP_ACCOUNT_OWNER'])">
      <span class="material-icons">person</span>
    </button>
    <button mat-flat-button class="text-uppercase" routerLink="/logout">
      <span class="material-icons">logout</span>
    </button>
  </div>
</header>

