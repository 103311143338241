import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdminComponent} from "./admin.component";
import {AdminDashboardComponent} from './admin-dashboard/admin-dashboard.component';
import {NotificationsManagementComponent} from './notifications-management/notifications-management.component';
import {AdminRoutingModule} from "./admin-routing.module";
import {FooterModule} from "../shared/footer/footer.module";
import {AdminHeaderComponent} from './admin-header/admin-header.component';
import {AdminHeaderModule} from "./admin-header/admin-header.module";
import {TranslateModule} from '@ngx-translate/core';
import {MatSortModule} from '@angular/material/sort';
import {MatSelectModule} from "@angular/material/select";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatTableModule} from "@angular/material/table";
import {MatStepperModule} from "@angular/material/stepper";
import {ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatRadioModule} from "@angular/material/radio";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatDialogModule} from '@angular/material/dialog';
import {NgxEchartsModule} from "ngx-echarts";
import {MatRippleModule} from "@angular/material/core";
import {MatCardModule} from "@angular/material/card";
import {NotificationsFormComponent} from './notifications-management/notifications-form/notifications-form.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {EditorModule} from "@tinymce/tinymce-angular";
import {RouterModule} from '@angular/router';
import {ConfirmationDialogModule} from "../shared/confirmation-dialog/confirmation-dialog.module";
import {UserManagementComponent} from "./user-management/user-management.component";
import {UserFormComponent} from "./user-management/user-form/user-form.component";
import {MatChipsModule} from '@angular/material/chips';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatIconModule} from "@angular/material/icon";
import {MatListModule} from '@angular/material/list';
import {InternalUsersComponent} from "./internal-users/internal-users.component";
import {InternalFormComponent} from "./internal-users/internal-form/internal-form.component";
import {CmsModule} from "../shared/cms/cms.module";


@NgModule({
  declarations: [
    AdminComponent,
    AdminDashboardComponent,
    NotificationsManagementComponent,
    AdminHeaderComponent,
    NotificationsFormComponent,
    UserManagementComponent,
    UserFormComponent,
    InternalUsersComponent,
    InternalFormComponent
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    AdminHeaderModule,
    ConfirmationDialogModule,
    EditorModule,
    FooterModule,
    MatRippleModule,
    MatCardModule,
    MatTableModule,
    MatStepperModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatRadioModule,
    MatDatepickerModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatDialogModule,
    TranslateModule,
    MatSortModule,
    MatSlideToggleModule,
    RouterModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    MatChipsModule,
    MatPaginatorModule,
    MatIconModule,
    MatListModule,
    CmsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AdminSectionModule {
}
