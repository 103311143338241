<h1 class="title">Nachlieferung</h1>
<app-idf-select (selectedIdf)="loadDeliveries($event,true)"></app-idf-select>

<app-notifications></app-notifications>

<mat-spinner *ngIf="loading" [diameter]="50"></mat-spinner>
<div *ngIf="!loading">
  <div class="d-flex jcsb aie">
    <p>
      <button mat-flat-button color="primary"
              (click)="addNachlieferung()" [disabled]="!canEdit">Artikel Aufnehmen <span class="material-icons" >post_add</span></button>
    </p>
    <div>
      <p class="text-center">Download:</p>
      <p class="d-flex jcsb">
        <button mat-flat-button color="primary" (click)="downloadCsv()"><span>.csv</span></button>
        <button mat-flat-button class="ml-s" color="primary" (click)="downloadPdf()"><span>.pdf</span></button>
      </p>
    </div>
  </div>

  <mat-table [dataSource]="dataSource" class="shadow" matSort #dataTable matSortActive="orderdate"
             matSortDirection="asc" matSortDisableClear>
    <!-- Order date Column -->
    <ng-container matColumnDef="orderdate">
      <mat-header-cell *matHeaderCellDef mat-sort-header=""> Datum</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.orderdate | date: 'shortDate'}} </mat-cell>
    </ng-container>

    <!-- PZN Column -->
    <ng-container matColumnDef="articlecode">
      <mat-header-cell *matHeaderCellDef mat-sort-header> PZN</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.articlecode}} </mat-cell>
    </ng-container>

    <!-- Product Name Column -->
    <ng-container matColumnDef="articlename">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Artikelname</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.articlename}} </mat-cell>
    </ng-container>

    <!-- Unit Column -->
    <ng-container matColumnDef="unit">
      <mat-header-cell *matHeaderCellDef> Einheit</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.unit}} </mat-cell>
    </ng-container>

    <!-- Quantity Column -->
    <ng-container matColumnDef="quantity">
      <mat-header-cell *matHeaderCellDef> Offene Bestellmenge</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.quantity}}
        <ng-container *ngIf="showEdit[element.position]">
          <form [formGroup]="maxQuantityForm" class="table-edit d-flex"
                (submit)="update(element.delivery)">
            <input name="updatedQuantity"
                   id="updatedQuantity-{{element.position}}"
                   type="text"
                   class=""
                   formControlName="maxQty"/>
            <button [disabled]="!maxQuantityForm.valid" type="submit"
                    [ngClass]="maxQuantityForm.valid ? 'cursor-pointer' : ''">
              <span class="material-icons" *ngIf="!maxQuantityForm.valid">block</span>
              <span class="material-icons" *ngIf="maxQuantityForm.valid">check</span>
            </button>
            <mat-error *ngIf="!maxQuantityForm.valid" matTooltip="Eine Erhöhung der Menge ist nicht möglich.">
              <span
                class="material-icons">help</span>
            </mat-error>
          </form>
        </ng-container>
      </mat-cell>
    </ng-container>

    <!-- Info Supplier -->
    <ng-container matColumnDef="delivery_info">
      <mat-header-cell *matHeaderCellDef> Info lt. Hersteller</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.delivery_information.getDeliveryDate() | translate}}</mat-cell>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <a matTooltip="Bearbeiten"
           (click)="showForm(element.quantity); showEdit = []; showEdit[element.position] = !showEdit[element.position];"
           class="mr-s"><span class="material-icons">edit</span></a>
        <a matTooltip="Löschen" (click)="delete(element.delivery);" class="m-xs"><span
          class="material-icons">delete</span></a>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" ></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" matRipple ></mat-row>
  </mat-table>

  <div *ngIf="!displayData.length" class="my-m">
    Keine Nachlieferungen
  </div>

</div>
