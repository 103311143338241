<footer class="footer container">
  <mat-divider></mat-divider>
  <p class="text-small">
    <span>© {{ today |date: 'YYYY' }} Hageda-Stumpf GmbH & Co. KG</span>
    <span class="sep"></span>
    <a [routerLink]="open ? '/open/page/impressum' : '/page/impressum'" class="link">Impressum</a>
    <span class="sep">|</span>
    <a [routerLink]="open ? '/kontakt' : '/kontaktformular'" class="link">Kontakt</a>
    <span class="sep">|</span>
    <a href="https://dehapdsacc.blob.core.windows.net/cms/AGBs_Hageda.pdf" class="link" target="_blank">AGB</a>
    <span class="sep">|</span>
    <a class="link" [routerLink]="open ? '/open/page/datenschutz' : '/page/datenschutz'">
      Datenschutz
    </a>
    <span class="sep">|</span>
    <a class="link" [routerLink]="open ? '/open/page/nutzungsbedingungen' : '/page/nutzungsbedingungen'">
      Nutzungbedingungen
    </a>
    <span class="sep">|</span>
    <a class="link" [routerLink]="open ? '/open/page/hgf' : '/page/hgf'">
      Häufig gestellte Fragen
    </a>
  </p>
</footer>
