<h1 class="title"> {{ description.title }}</h1>
<app-idf-select (selectedIdf)="getPersonalPreorders($event)"></app-idf-select>

<div [innerHTML]="description.description"></div>

<mat-accordion>
  <mat-expansion-panel *ngFor="let preorder of preorders">
    <mat-expansion-panel-header>
      <mat-panel-title class="title red">
        {{ preorder.vendor }}
      </mat-panel-title>
      <mat-panel-description *ngIf="preorder.show_dates">
        {{ preorder.end | date: 'medium'}}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <table mat-table [dataSource]="preorder.products">
      <form [formGroup]="getForm(preorder.pre_order_period_id)">
        <!-- PZN Column -->
        <ng-container matColumnDef="pzn">
          <th mat-header-cell *matHeaderCellDef>PZN</th>
          <td mat-cell *matCellDef="let element"> {{element.id.pzn}} </td>
        </ng-container>
        <ng-container matColumnDef="unit">
          <th mat-header-cell *matHeaderCellDef>Einheit</th>
          <td mat-cell *matCellDef="let element"> {{element.unit}} </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Artikelbezeichnung</th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
        <ng-container matColumnDef="comment">
          <th mat-header-cell *matHeaderCellDef>Anmerkungen</th>
          <td mat-cell *matCellDef="let element"> {{element.comment}} </td>
        </ng-container>
        <ng-container matColumnDef="aep">
          <th mat-header-cell *matHeaderCellDef>AEP</th>
          <td mat-cell *matCellDef="let element"> {{element.price_normal_aep | currency:'EUR':'symbol':'1.2-2'}} </td>
        </ng-container>
        <ng-container matColumnDef="qty">
          <th mat-header-cell *matHeaderCellDef>Bestellmenge</th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field class="mt-l preorders-input" appearance="outline">
              <mat-label>Menge</mat-label>
              <input matInput type="number" min="1" maxlength="4" class="text-center"
                     (change)="checkQty(getForm(preorder.pre_order_period_id), element.id.pzn, element.name)"
                     formControlName="{{element.id.pzn}}">
            </mat-form-field>
          </td>
        </ng-container>
      </form>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-action-row class="aic">
      <span>Hinweis: {{ preorder.hint }} </span>
      <button mat-flat-button color="primary" class="mr-m" (click)="saveForm(preorder.pre_order_period_id)">Reservierung
        speichern
      </button>
    </mat-action-row>
    <p> {{ preorder.disclaimer }}</p>
  </mat-expansion-panel>
</mat-accordion>
