<div class="mt-m">
  <mat-spinner *ngIf="loading" [diameter]="50"></mat-spinner>
  <div *ngIf="tours.length === 0 && !loading" class="d-flex flex-column">
    <p class="text-medium text-center">Zur Zeit ist keine Lieferung unterwegs.</p>
    <p class="text-uppercase">details ></p>
  </div>
  <div *ngIf="tours.length > 0 && !loading">
    <div class="in-delivery">
      <div class="d-flex">
        <span class="material-icons">local_shipping</span>
        <p class="my-0 ml-s"> {{ getTour(tours[0].tour_id) }}</p>
      </div>
      <div class="text-center time {{delay(tours[0])}}">{{
          tours[0]['expected_time_of_arrival_max'] ? (tours[0]['expected_time_of_arrival_max'].substring(11, 16))
            : (tours[0]['planned_time_of_arrival'].substring(11, 16))
        }}
      </div>
      <div class="text-center">{{
          tours[0]['delay_in_minutes'] ? (tours[0]['delay_information'] >= 10 ? 'leichte Verspätung'
            : tours[0]['delay_information']) : 'Geplante Ankuft'
        }}
      </div>
    </div>
  </div>
</div>
