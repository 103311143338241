import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import {DocRequest} from "../../../core/invoices/api/doc.request.model";
import {InvoicesRestService} from "../../../core/invoices/services/invoices.rest.service";
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {MatTableDataSource, MatTable} from "@angular/material/table";


@Component({
  selector: 'app-invoices-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnDestroy, OnInit, OnChanges {
  private ngUnsubscribe$ = new Subject<boolean>();
  displayedColumns: string[] = ['invNr', 'type', 'idf', 'invDate', 'download'];
  @Input() documents: any[] = [];
  dataSource = new MatTableDataSource();
  loading = true;


  constructor(private invoicesRestService: InvoicesRestService,){
  }

  ngOnInit(){
    this.dataSource.data=this.documents;
    this.loading = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loading = true;

    if (changes['documents']) {
      this.dataSource = new MatTableDataSource(this.documents);
      this.loading = false;
    }
  }



  downloadDocument(document: any, docType: any, $event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
    let docRequest = new DocRequest({
      docDate: document.inv_date,
      docNr: document.inv_nr,
      docType: docType.attribute,
      idf: document.idf,
      vz: 21
    });
    this.invoicesRestService.getDocument(docRequest);
  }


  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy(): void {
    console.log('destroy')
    this.ngUnsubscribe$.next(true);
    this.ngUnsubscribe$.complete();
  }

}
