<form [formGroup]="subuserForm" class="mb-l">
  <h2 class="subheader">Persönliche Angaben</h2>
  <div class="d-flex jcsb flex-column">
    <mat-form-field appearance="outline">
      <mat-label>Anrede</mat-label>
      <mat-select formControlName="salutation">
        <mat-option *ngFor="let salutation of getSalutationValues()" [value]="salutation">
          {{ salutation }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Titel</mat-label>
      <input matInput formControlName="title">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Vorname</mat-label>
      <input matInput formControlName="first_name">
      <mat-error *ngIf="first_name.hasError('required')">
        Required first name
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput formControlName="last_name"/>
      <mat-error *ngIf="last_name.hasError('required')">
        Required first name
      </mat-error>
    </mat-form-field>
  </div>
  <div>
    <h2 class="subheader">Bitte vergeben Sie ein Passwort.</h2>
    <div class="d-flex jcsb flex-column">
      <mat-form-field appearance="outline">
        <mat-label>Neues Passwort</mat-label>
        <input matInput
               [type]="hide ? 'password' : 'text'"
               formControlName="password"
               tabindex="1" (change)=checkIfPassValid($event)>
        <button mat-icon-button
                matSuffix
                (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
          <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
        <mat-error *ngIf="password.hasError('minlength')">
          Der Passwort muss mindestens 8 Zeichen lang sein.
        </mat-error>
        <mat-error *ngIf="password.hasError('passwordval')">
          Der Passwort muss mindestens ein Kleinbuchstabe, ein Großbuchstabe, eine Zahl und eine Sonderzeichen haben.
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Passwort wiederholen</mat-label>
        <input matInput [type]="hide ? 'password' : 'text'" formControlName="password_repeat" tabindex="2"
                (change)=checkIfPassMatch($event)>
        <button mat-icon-button
                matSuffix
                (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-error *ngIf="password_repeat.hasError('mustMatch')">
          Die Passwörter stimmen nicht überein.
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <h2 class="subheader">Bitte wählen Sie die Zugriffsrechte aus, die der Mitbenutzer bekommen soll
    <button mat-flat-button (click)="treeControl.expandAll()" matTooltip="Alle ausklappen"><span class="material-icons">expand_more</span>
    </button>
    <button mat-flat-button (click)="treeControl.collapseAll()" matTooltip="Alle einklappen"><span
      class="material-icons">expand_less</span></button>
  </h2>
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
      <div class="mat-icon"></div>
      <mat-checkbox class="checklist-leaf-node"
                    [checked]="checklistSelection.isSelected(node)"
                    (change)="authLeafSelectionToggle(node)"
                    [disabled]="node.readonly">{{ node.label | translate }}
      </mat-checkbox>
      <!-- use a disabled button to provide padding for tree leaf -->
      {{ node.name }}
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
      <div matTreeNodeToggle
           [attr.aria-label]="'toggle ' + node.label">
        <mat-icon class="mat-icon-rtl-mirror">
          {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
        </mat-icon>
      </div>
      <mat-checkbox [checked]="descendantsAllSelected(node)"
                    [indeterminate]="descendantsPartiallySelected(node)"
                    (change)="todoItemSelectionToggle(node)"
                    [disabled]="node.readonly">
        {{ getLabel(node.label) | translate }}
      </mat-checkbox>
    </mat-tree-node>
  </mat-tree>
</form>
<button mat-flat-button (click)="cancelForm()">Abbrechen</button>
<button mat-flat-button color="primary" (click)="submit()">{{ buttonText }}</button>
