import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, OnChanges, AfterViewInit} from '@angular/core';
import {SubUserModel} from "../../../core/user/subuser.model";
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {AuthorizationNodeModel} from "../../../core/user/models/authorization.node.model";
import {AuthorizationFlatNodeInterface} from "../../../core/user/interfaces/authorisation.flat.node.interface";
import {Salutation} from "../../../core/user/models/salutation.enum";
import {FlatIdfAuthoritiesModel} from "../../../core/user/models/flat.idf.authorities.model";
import {SubUserRestService} from "../../../core/user/services/subuser.rest.service";
import {takeUntil} from 'rxjs/operators';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {DialogComponent} from "../../../shared/dialog/dialog.component";
import {DialogTextModel} from "../../../core/cms/models/dialog.text.model";

@Component({
  selector: 'app-subuser',
  templateUrl: './subuser.component.html',
  styleUrls: ['./subuser.component.scss']
})
export class SubuserComponent implements OnInit, OnDestroy, OnChanges {
  @Input() subUser: SubUserModel = new SubUserModel({});
  @Input() public authTree: AuthorizationNodeModel[] = [new AuthorizationNodeModel({})];
  @Input() public allIdfs: any[] = [];
  @Output() public cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() public notify: EventEmitter<any> = new EventEmitter<any>();
  public ngUnsubscribe$ = new Subject<void>();
  public enableTree = false;
  public hide = true;
  public subuserForm: any;
  public passChange = false;

  public salutation = new FormControl('');
  public title = new FormControl('');
  public first_name = new FormControl('', Validators.required);
  public last_name = new FormControl('', Validators.required);
  public password = new FormControl('');

  public password_repeat = new FormControl('');
  public checklistSelection = new SelectionModel<AuthorizationFlatNodeInterface>(true /* multiple */);
  public treeControl: any;
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  public flatNodeMap = new Map<AuthorizationFlatNodeInterface, AuthorizationNodeModel>();
  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  public nestedNodeMap = new Map<AuthorizationNodeModel, AuthorizationFlatNodeInterface>();
  public treeFlattener: any;
  public dataSource: any;
  public transformer = (node: AuthorizationNodeModel, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.label === node.label
      ? existingNode
      : new AuthorizationNodeModel();
    flatNode.label = node.label;
    flatNode.level = level;
    flatNode.idf = node.idf;
    flatNode.authority = node.authority;
    flatNode.readonly = node.readonly;
    flatNode.expandable = !!node.children && node.children.length > 0;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };
  public getLevel = (node: AuthorizationFlatNodeInterface) =>
    node.level;
  public isExpandable = (node: AuthorizationFlatNodeInterface) =>
    node.expandable;
  public getChildren = (node: AuthorizationNodeModel): AuthorizationNodeModel[] =>
    node.children;
  public hasChild = (_: number, node: AuthorizationFlatNodeInterface) => node.expandable;
  public salutations = Salutation;
  public buttonText = "Mitbenutzer Anlegen"

  constructor(private formBuilder: FormBuilder,
              private subUserRestService: SubUserRestService,
              public dialog: MatDialog
  ) {
    this.initForm();
    if (this.subUser) {
      this.buttonText = "Mitbenutzer Aktualisieren"
    }
  }

  ngOnInit() {
    if(!this.enableTree) {
      this.initTree();
    }
  }


  getSalutationValues() {
    return Object.values(this.salutations);
  }

  public initTree() {
    this.checklistSelection = new SelectionModel<AuthorizationFlatNodeInterface>(true /* multiple */);
    this.treeControl = new FlatTreeControl<AuthorizationFlatNodeInterface>(this.getLevel, this.isExpandable);
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    const treeCopy = Object.assign([], this.authTree);
    this.treeControl.dataNodes = treeCopy;
    this.dataSource.data = treeCopy;
    this.enableTree = true;
  }

  public initForm() {
    delete this.subuserForm;
    this.subuserForm = this.formBuilder.group({
      salutation: this.salutation,
      title: this.title,
      first_name: this.first_name,
      last_name: this.last_name,
      password: this.password,
      password_repeat: this.password_repeat
    })
  }


  /**
   * Loads subUser model into form if it is changed.
   * @param {event} e
   */
  ngOnChanges(e: any) {
    if (e.subUser && e.subUser.currentValue) {
      this.initForm();
      this.initTree();
      // this.subUser = new SubUserModelRepository(e.subUser.currentValue);
      const user = e.subUser.currentValue;
      this.subuserForm.reset();
      this.subuserForm.updateValueAndValidity();
      this.subuserForm.patchValue(user);
      if (e.subUser.currentValue.id) {
        // this.setEditValidators();
        if (Object.keys(e.subUser.currentValue.flat_idf_authorities).length > 0) {
          this.treeControl.dataNodes.forEach((an: AuthorizationNodeModel) => {
            if (an.idf && an.authority && this.subUser.isGranted(an)) {
              an.granted = (this.subUser.isGranted(an)) ? 1: 0;
              this.todoItemSelectionToggle(an);
              this.treeControl.expand(an);
            }
          });
        }
      }
    }
  }


  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: AuthorizationFlatNodeInterface): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node).filter((a: any) => !a.readonly);
    const descAllSelected = descendants.length > 0 && descendants.every((child: any) => {
       return this.checklistSelection.isSelected(child)
    }
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
    // if (!descAllSelected) {
    //     this.checklistSelection.deselect(node);
    // } else if (descAllSelected) {
    //     this.checklistSelection.select(node);
    // }
  }

  /** Get the parent node of a node */
  getParentNode(node: AuthorizationFlatNodeInterface): AuthorizationFlatNodeInterface | null {
    const currentLevel = this.getLevel(node);
    if (currentLevel < 1) {
      return null;
    }
    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  /** Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: AuthorizationFlatNodeInterface): void {
    let parent: AuthorizationFlatNodeInterface | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  descendantsAllSelected(node: AuthorizationFlatNodeInterface): boolean {
    const descendants = this.treeControl.getDescendants(node).filter((a: any) => !a.readonly);
    return descendants.every((child: any) =>
      this.checklistSelection.isSelected(child)
    );
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: AuthorizationFlatNodeInterface): boolean {
    const descendants = this.treeControl.getDescendants(node).filter((a: any) => !a.readonly);
    const result = descendants.some((child: any) => {
      return this.checklistSelection.isSelected(child)
    });
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: AuthorizationFlatNodeInterface): void {
    this.checklistSelection.toggle(node);
    node.granted = Number(!node.granted);
    const descendants = this.treeControl.getDescendants(node);//.filter(a => !a.readonly);
    const writableDescendants = descendants.filter((a: any) => !a.readonly);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...writableDescendants)
      : this.checklistSelection.deselect(...writableDescendants);

    // Force update for the parent
    // descendants.every(child =>
    //     this.checklistSelection.isSelected(child)
    // );
    // descendants.forEach((child: AuthorizationFlatNodeInterface) => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }


  /** If label is iDF add name with it */
  public getLabel(label: string) {
    let idf;
    if (this.allIdfs) {
      idf = this.allIdfs.find((item: any) => label === item.idf);
    }
    return idf && idf.name ? idf.idf + ' (' + idf.name + ')' : label;
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  submit() {
    if (!this.subuserForm.valid) {
      return;
    }
    let data = this.subuserForm.value;

    const flat_idf_authorities = new FlatIdfAuthoritiesModel({flat_idf_authorities: this.createAuthConfigFromSelectionModel(this.checklistSelection)});
    const request = new SubUserModel({...data, ...flat_idf_authorities.flat_idf_authorities});
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;

    if (this.subUser?.id) {
      request.id = this.subUser.id;
      this.subUserRestService.update(request).pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res: any) => {
          var content = res.return_object.new_password ? 'Ihr Mitbenutzer <b>' + res.return_object.username + '</b> konnte erfolgreich geändert werden.<br/>' +
            'Bitte teilen Sie Ihrem Mitbenutzer sein' +
            ' neues <b>Passwort: ' + res.return_object.new_password + '</b> mit.'
            : 'Ihr Mitbenutzer für <b>' + this.first_name.value + ' '
            + this.last_name.value + '</b> konnte erfolgreich geändert werden.';

          dialogConfig.data = new DialogTextModel({
            title: 'Mitbenutzer erfolgreich geändert',
            content: content,
            type: 'close'
          });
          this.passChange = false;
          this.dialog.open(DialogComponent, dialogConfig)

        },
        (error: any) => {
        });
    } else {
      this.subUserRestService.create(request).pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res: any) => {
          dialogConfig.data = new DialogTextModel({
            title: 'Mitbenutzer erfolgreich angelegt',
            content: 'Ihr Mitbenutzer für <b>' + this.first_name.value + ' ' + this.last_name.value + '</b> wurde erfolgreich angelegt.<br/>' +
              'Bitte teilen Sie Ihrem neuen Mitbenutzer seinen <b> Benutzernamen: ' + res.return_object.username + '</b> und sein ' +
              ' <b>Passwort: ' + res.return_object.new_password + '</b> mit.',
            type: 'close'
          });
          this.dialog.open(DialogComponent, dialogConfig)
        },
        (error: any) => {
        });
    }
  }

  public createAuthConfigFromSelectionModel(pgas: SelectionModel<AuthorizationFlatNodeInterface>): Record<string, any> {
    const flatIdfAuthorities: Record<string, any> = {};

    pgas.selected.forEach((pga: any) => { // potentially granted authority
      const idf = pga.idf.toString();
      if (pga.idf && pga.authority && !flatIdfAuthorities.hasOwnProperty(idf)) {
        flatIdfAuthorities[idf] = [];
      }
      if (pga.authority) {
        flatIdfAuthorities[idf].push(pga.authority);
      }
    });
    return new FlatIdfAuthoritiesModel({flat_idf_authorities: flatIdfAuthorities});
  }

  cancelForm() {
    this.cancel.emit(true);
  }

  public checkIfPassValid($event: any) {
    const value = this.subuserForm.get('password').value;
    if (!value || value.length <= 0) {
      this.subuserForm.get('password').setErrors({})
    } else if (value.length < 8) {
      this.subuserForm.get('password').setErrors({minlength: true});
    } else if(value.length >= 8){
      if (((/\d/.test(value)) && /[A-Z]/.test(value) && /[a-z]/.test(value) && /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value))) {
        this.subuserForm.get('password').setErrors()
      } else {
        this.subuserForm.get('password').setErrors({passwordval: true})
      }
    }
  }

  public checkIfPassMatch($event: any) {
    const value = this.subuserForm.get('password_repeat').value;

    if (value !== this.subuserForm.get('password').value) {
      this.subuserForm.get('password_repeat').setErrors({mustMatch: true})
    } else {
      this.subuserForm.get('password_repeat').setErrors(null)
    }
  }

  authLeafSelectionToggle(node: AuthorizationFlatNodeInterface) {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }
}
