<h2>Sendungsverfolgung</h2>
<div class="d-flex jcsb">
  <div>
  <mat-button-toggle-group appearance="legacy" name="day" aria-label="Status" color="primary"
                           (change)="loadTours($event)" class="mb-l">
    <mat-button-toggle value="delivery" checked="true">In Zustellung</mat-button-toggle>
    <mat-button-toggle value="delivered">Geliefert</mat-button-toggle>
    <mat-button-toggle value="yesterday">Gestern</mat-button-toggle>
  </mat-button-toggle-group>
  </div>
  <div>
    <app-idf-select (selectedIdf)="setIdf($event)"></app-idf-select>
  </div>
</div>
<mat-spinner *ngIf="loading" [diameter]="50"></mat-spinner>
<p *ngIf="!loading && tours.length === 0 && !current">Zur Zeit ist keine Lieferung unterwegs.</p>
<div *ngIf="!loading && status === 'delivery' && current" class="mb-l">
  <div class="tour">
    <h3>Tour {{ getTour(current.tour_id) }}</h3>
  </div>
  <div class="arrival">
    <div class="d-flex jcsb">
      <h3>{{ current['expected_time_of_arrival_max'] && !current['delivered_at'] ? 'Voraussichtliche' : 'Geplante' }}
        Ankunft
      </h3>
      <div class="mb-l">{{
          getMinutes(current['delay_in_minutes']) !== null ? (getMinutes(current['delay_in_minutes']) >= 10
          && getMinutes(current['delay_in_minutes']) < 30
            ? 'leichte Verspätung' : current['delay_information']) : 'derzeit keine Information verfügbar'
        }}
      </div>
    </div>
    <div class="text-center text-huge time {{delay(current)}} mb-l">{{
        current['expected_time_of_arrival_max'] ? (current['expected_time_of_arrival_max'] | date:'shortTime')
          : (current['planned_time_of_arrival'] | date:'shortTime')
      }}
    </div>
    <div class="status d-flex jcsb mb-s">
      <div class="w-30 text-left d-flex"><span class="material-icons">store_mall_directory</span>
        <p class="my-0 ml-s"> Im Vertriebszentrum</p>
      </div>
      <div class="w-30 text-center d-flex jcc"><p class="my-0 mr-s">Auf dem Weg </p><span class="material-icons">local_shipping</span>
      </div>
      <div class="w-30 text-right d-flex jce"><p class="my-0 mr-s">Geliefert </p><span class="material-icons">my_location</span>
      </div>
    </div>
    <mat-progress-bar mode="determinate" value="{{tourPercent(current)}}"></mat-progress-bar>
  </div>
  <div>
    <div class="invoices w-100">
      <h3>Belegnummern</h3>
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Filter Belege nach PZN</mat-label>
        <input matInput name="belegefilter" (input)="setInvoiceFilter($event, current)" class="w-100"/>
      </mat-form-field>
      <mat-list class="invoice-list">
        <mat-list-item *ngFor="let doc of docs[current.tour_id]" class="p-s mb-m">
          <div class="d-flex jcsb w-100 mb-s">
            <a (click)="downloadInvoice(doc.doc);$event.preventDefault(); $event.stopPropagation()">{{ doc.doc }}</a>
            <p class="m-0">Beleg zur Sammelrechnung</p>
            <button mat-mini-fab color="primary" class="mx-s"
                    (click)="downloadInvoice(doc.doc);$event.preventDefault(); $event.stopPropagation()"><span
              class="material-icons">download</span></button>
          </div>
          <mat-expansion-panel hideToggle (click)="filter=''" class="mb-xs">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <p>Artikel</p>
              </mat-panel-title>
              <mat-panel-description>
                Total: {{ getProductsForInvoice(current.package, doc.doc).length }}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>PZN oder Artikelname</mat-label>
              <input matInput (input)="setFilter($event, doc.doc, current.package)" class="w-100"/>
            </mat-form-field>
            <div class="products">
              <div class="d-flex jcsb px-m w-100">
                <div class="w-20">PZN</div>
                <div class="w-50 text-left">Artikelbezeichnung</div>
                <div class="w-10">Menge</div>
              </div>
              <mat-list>
                <mat-list-item
                  *ngFor="let product of filtered[doc.doc]">
                  <div class="d-flex jcsb">
                    <div class="w-20">{{ product.article_code }}</div>
                    <div class="w-50 text-left">{{ product.article_name }}</div>
                    <div class="w-10 text-center">{{ product.expected_delivery_quantity }}</div>
                  </div>
                </mat-list-item>
              </mat-list>
            </div>
          </mat-expansion-panel>
        </mat-list-item>
      </mat-list>
    </div>
  </div>
</div>
<div *ngIf="!loading && tours.length > 0 && status ==='delivery'">
  <h3>Bevorlegende Sendungen</h3>
  <div *ngFor="let tour of tours" class="pb-s">
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="d-flex jcsb">
            <span class="material-icons">local_shipping</span>
            <p class="my-0 ml-s"> {{ getTour(tour.tour_id) }}</p>
          </div>
        </mat-panel-title>
        <mat-panel-description>
          <div class="arrival d-flex jcsb w-100">
            <div class="w-20">{{
                tour['expected_time_of_arrival_max'] && !tour['delivered_at'] ? 'Voraussichtliche' : 'Geplante'
              }}
              Ankunft
            </div>
            <div class="text-center text-bold text-large time {{delay(tour)}}">{{
                tour['expected_time_of_arrival_max'] ? (tour['expected_time_of_arrival_max'] | date:'shortTime')
                  : (tour['planned_time_of_arrival'] | date:'shortTime')
              }}
            </div>
            <div class="w-30 text-right">{{
                getMinutes(tour['delay_in_minutes']) !== null ? (getMinutes(tour['delay_in_minutes']) >= 10
                && getMinutes(tour['delay_in_minutes']) < 30 ? 'leichte Verspätung'
                  : tour['delay_information']) : 'derzeit keine Information verfügbar'
              }}
            </div>
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="arrival">
        <div class="text-center text-huge time {{delay(tour)}} mb-l">{{
            tour['expected_time_of_arrival_max'] ? (tour['expected_time_of_arrival_max'] | date:'shortTime')
              : (tour['planned_time_of_arrival'] | date:'shortTime')
          }}
        </div>
      </div>
      <div class="d-flex jcsb details">
        <div class="invoices w-100">
          <h3>Belegnummern</h3>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Filter Belege nach PZN</mat-label>
            <input matInput name="belegefilter" (input)="setInvoiceFilter($event, tour)" class="w-100"/>
          </mat-form-field>
          <mat-list class="invoice-list">
            <mat-list-item *ngFor="let doc of docs[tour.tour_id]" class="p-s mb-m">
              <div class="d-flex jcsb  w-100 mb-s">
                <a (click)="downloadInvoice(doc.doc);$event.preventDefault(); $event.stopPropagation()">{{ doc.doc }}
                  <mat-icon></mat-icon>
                </a>
                <button mat-mini-fab color="primary" class="mx-s"
                        (click)="downloadInvoice(doc.doc);$event.preventDefault(); $event.stopPropagation()"><span
                  class="material-icons">download</span></button>
              </div>
              <mat-expansion-panel hideToggle (click)="filter=''" class="mb-xs">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <p>Artikel</p>
                  </mat-panel-title>
                  <mat-panel-description>
                    Total: {{ getProductsForInvoice(tour.package, doc.doc).length }}
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>PZN oder Artikelname</mat-label>
                  <input matInput name="filter" (input)="setFilter($event, doc.doc, tour.package)" class="w-100"/>
                </mat-form-field>
                <div class="products">
                  <div class="d-flex jcsb px-m w-100">
                    <div class="w-20">PZN</div>
                    <div class="w-50 text-left">Artikelbezeichnung</div>
                    <div class="w-10">Menge</div>
                  </div>
                  <mat-list>
                    <mat-list-item
                      *ngFor="let product of filtered[doc.doc]">
                      <div class="d-flex jcsb">
                        <div class="w-20">{{ product.article_code }}</div>
                        <div class="w-50 text-left">{{ product.article_name }}</div>
                        <div class="w-10 text-center">{{ product.expected_delivery_quantity }}</div>
                      </div>
                    </mat-list-item>
                  </mat-list>
                </div>
              </mat-expansion-panel>
            </mat-list-item>
          </mat-list>
        </div>
      </div>
    </mat-expansion-panel>
  </div>
</div>
<div *ngIf="!loading && status !=='delivery'">
  <div *ngFor="let tour of delivered; index as i;" class="pb-s">
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="d-flex jcsb">
            <span class="material-icons">local_shipping</span>
            <p class="my-0 ml-s"> {{ getTour(tour.tour_id) }}</p>
          </div>
        </mat-panel-title>
        <mat-panel-description>
          <div class="arrival d-flex jcsb w-100" *ngIf="status === 'yesterday'">
            <div class="w-20">
              <span *ngIf="!tour['delivered_at']">{{
                  tour['expected_time_of_arrival_max'] ? 'Vorausichtliche' : 'Geplante'
                }}
              </span>
              Ankunft
            </div>
            <div class="text-center text-bold text-large time {{delay(tour)}}" *ngIf="!tour['delivered_at']">{{
                tour['expected_time_of_arrival_max'] ? (tour['expected_time_of_arrival_max'].substring(11, 16))
                  : (tour['planned_time_of_arrival'].substring(11, 16))
              }}
            </div>
            <div class="text-center text-bold text-large time {{delay(tour)}}" *ngIf="tour['delivered_at']">{{
                tour['delivered_at'].substring(11, 16)
              }}
            </div>
            <div class="w-30 text-right">{{
                tour['delay_in_minutes'] ? tour['delay_information'] : 'derzeit keine Information verfügbar'
              }}
            </div>
          </div>
          <div class="arrival d-flex jcsb w-100" *ngIf="status === 'delivered'">
            <div class="w-20">Ankunft
            </div>
            <div class="text-center text-bold text-large time {{delay(tour)}}">{{
                (tour['delivered_at'] ? (tour['delivered_at'] | date:'shortTime')
                  : (tour['planned_time_of_arrival'] | date:'shortTime'))
              }}
            </div>
            <div class="w-30 text-right">{{
                getMinutes(tour['delay_in_minutes']) !== null ? (getMinutes(tour['delay_in_minutes']) >= 10
                && getMinutes(tour['delay_in_minutes']) < 30 ? 'leichte Verspätung'
                  : tour['delay_information']) : 'derzeit keine Information verfügbar'
              }}
            </div>
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="arrival">
        <div class="text-center text-bold text-large time {{delay(tour)}}">{{
            (tour['delivered_at'] ? (tour['delivered_at'] | date:'shortTime')
              : (tour['planned_time_of_arrival']| date:'shortTime'))
          }}
        </div>
      </div>
      <div class="d-flex jcsb details">
        <div class="invoices w-100">
          <h3>Belegnummern</h3>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Filter Belege nach PZN</mat-label>
            <input matInput name="belegefilter" (input)="setInvoiceFilter($event, tour)" class="w-100"/>
          </mat-form-field>
          <mat-list class="invoice-list">
            <mat-list-item *ngFor="let doc of docs[tour.tour_id]" class="p-s mb-m">
              <div class="d-flex jcsb w-100 mb-s">
                <a
                  (click)="downloadInvoice(doc.doc);$event.preventDefault(); $event.stopPropagation()">{{ doc.doc }}</a>
                <p class="m-0">Beleg zur Sammelrechnung</p>
                <button mat-mini-fab color="primary" class="mx-s"
                        (click)="downloadInvoice(doc.doc);$event.preventDefault(); $event.stopPropagation()"><span
                  class="material-icons">download</span></button>
              </div>
              <mat-expansion-panel hideToggle (click)="filter=''" class="mb-xs">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <p>Artikel</p>
                  </mat-panel-title>
                  <mat-panel-description>
                    Total: {{ getProductsForInvoice(tour.package, doc.doc).length }}
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>PZN oder Artikelname</mat-label>
                  <input matInput (input)="setFilter($event, doc.doc, tour.package)" class="w-100"/>
                </mat-form-field>
                <div class="products">
                  <div class="d-flex jcsb px-m w-100">
                    <div class="w-20">PZN</div>
                    <div class="w-50 text-left">Artikelbezeichnung</div>
                    <div class="w-10">Menge</div>
                  </div>
                  <mat-list>
                    <mat-list-item
                      *ngFor="let product of filtered[doc.doc]">
                      <div class="d-flex jcsb">
                        <div class="w-20">{{ product.article_code }}</div>
                        <div class="w-50 text-left">{{ product.article_name }}</div>
                        <div class="w-10 text-center">{{ product.expected_delivery_quantity }}</div>
                      </div>
                    </mat-list-item>
                  </mat-list>
                </div>
              </mat-expansion-panel>
            </mat-list-item>
          </mat-list>
        </div>
      </div>
    </mat-expansion-panel>
  </div>
</div>
