import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PrivateRoutingModule} from './private-routing.module';
import {InvoicesComponent} from './invoices/invoices.component';
import {DashboardComponent} from "./dashboard/dashboard.component";
import {MatRippleModule} from "@angular/material/core";
import {MatCardModule} from "@angular/material/card";
import {RevenueComponent} from './revenue/revenue.component';
import {SubsequentDeliveriesComponent} from './subsequent-deliveries/subsequent-deliveries.component';
import {MatTableModule} from "@angular/material/table";
import {MatStepperModule} from "@angular/material/stepper";
import {ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatRadioModule} from "@angular/material/radio";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatDialogModule} from '@angular/material/dialog';
import {NgxEchartsModule} from "ngx-echarts";
import {IdfSelectComponent} from './idf-select/idf-select.component';
import {LogoutComponent} from './logout/logout.component';
import {MatSelectModule} from "@angular/material/select";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatTooltipModule} from "@angular/material/tooltip";
import {FirstLoginDialogComponent} from './dashboard/first-login-dialog/first-login-dialog.component';
import {PrivateComponent} from "./private.component";
import {FooterModule} from "../shared/footer/footer.module";
import {HeaderModule} from "../shared/header/header.module";
import {NotificationsComponent} from "../shared/notifications/notifications.component";
import {TranslateModule} from "@ngx-translate/core";
import {MatSortModule} from "@angular/material/sort";
import {ConfirmationDialogModule} from "../shared/confirmation-dialog/confirmation-dialog.module";
import {DialogModule} from "../shared/dialog/dialog.module";
import {ResultsComponent} from './invoices/results/results.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {AddFormComponent} from './subsequent-deliveries/add-form/add-form.component';
import {PopupModule} from "./popup/popup.module";
import {PreordersComponent} from "./preorders/preorders.component";
import {
  MatAccordion,
  MatExpansionModule
} from "@angular/material/expansion";
import {MatBadge} from "@angular/material/badge";
import {TrackTraceComponent} from "./track-trace/track-trace.component";
import {TrackTraceWidgetComponent} from "./track-trace/track-trace-widget/track-trace-widget.component";
import {MatListModule} from '@angular/material/list';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatDividerModule} from '@angular/material/divider';

@NgModule({
  declarations: [
    PrivateComponent,
    DashboardComponent,
    InvoicesComponent,
    RevenueComponent,
    SubsequentDeliveriesComponent,
    IdfSelectComponent,
    LogoutComponent,
    FirstLoginDialogComponent,
    NotificationsComponent,
    ResultsComponent,
    AddFormComponent,
    PreordersComponent,
    TrackTraceComponent,
    TrackTraceWidgetComponent
  ],
  imports: [
    CommonModule,
    PrivateRoutingModule,
    ConfirmationDialogModule,
    DialogModule,
    MatRippleModule,
    MatCardModule,
    MatTableModule,
    MatStepperModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatRadioModule,
    MatDatepickerModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    MatSelectModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    FooterModule,
    HeaderModule,
    MatDialogModule,
    TranslateModule,
    MatSortModule,
    MatAutocompleteModule,
    MatRippleModule,
    PopupModule,
    MatAccordion,
    MatExpansionModule,
    MatBadge,
    MatListModule,
    MatButtonToggleModule,
    MatProgressBarModule,
    MatDividerModule
  ],
  exports: [
    IdfSelectComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PrivateModule {
}
