<h1 class="title">Notices</h1>

<button mat-flat-button color="primary" (click)="addNotification()">Add Notification</button>
<mat-table [dataSource]="dataSource" class="shadow" matSort #dataTable matSortActive="orderdate" matSortDirection="asc" matSortDisableClear>
  <!-- Order date Column -->
  <ng-container matColumnDef="orderdate">
    <mat-header-cell *matHeaderCellDef mat-sort-header="">Aktiviert</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.active}} </mat-cell>
  </ng-container>

  <!-- PZN Column -->
  <ng-container matColumnDef="articleno">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Typ</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.type}} </mat-cell>
  </ng-container>

  <!-- Product Name Column -->
  <ng-container matColumnDef="articlename">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Title</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.title}} </mat-cell>
  </ng-container>

  <!-- Unit Column -->
  <ng-container matColumnDef="unit">
    <mat-header-cell *matHeaderCellDef>Datum</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.date}} </mat-cell>
  </ng-container>

  <!-- Quantity Column -->
  <ng-container matColumnDef="quantity">
    <mat-header-cell *matHeaderCellDef>Page</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.filter}} </mat-cell>
  </ng-container>

  <!-- Actions Column -->
  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let element">
      <a matTooltip="Bearbeiten"
         (click)="edit(element.notification)"
         class="mr-s"><span class="material-icons">edit</span></a>
      <a matTooltip="Löschen" (click)="delete(element.notification);" class="m-xs"><span
        class="material-icons">delete</span></a>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>

<div *ngIf="!dataSource.data.length" class="my-m">
  Keine Nachrichten
</div>
