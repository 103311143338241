<h1 class="title">Dashboard</h1>
<app-notifications></app-notifications>
<div class="tile-grid mt-m" *ngIf="!this.loading">

  <mat-card class="dashboard-card tile" matRipple routerLink="/belege" *ngIf="canAccessTile([
            'BULK_BILL_RECEIPT',
            'BULK_BILL',
            'CREDIT_NOTE',
            'VETERINARY_DRUG',
            'TRANSFUSION',
            'REIMPORT',
            'EXTENDED_BENEFIT'
          ])">
    <mat-card-header class="jcc">
      <mat-card-title class="text-uppercase subheader">Belege</mat-card-title>
    </mat-card-header>
    <mat-card-content class="d-flex text-large jcc my-m">
      <div class="d-flex jcc icon icon-belege">&nbsp;</div>
    </mat-card-content>
  </mat-card>
  <mat-card class="dashboard-card tile-umsatz" matRipple routerLink="/umsatz" *ngIf="canAccessTile(['MY_SALES'])">
    <mat-card-header class="jcc">
      <mat-card-title class="text-uppercase subheader">Umsatz</mat-card-title>
    </mat-card-header>
    <mat-card-content class="d-flex text-large jcc my-m">
      <div class="d-flex jcc icon icon-umsatz">&nbsp;</div>
    </mat-card-content>
  </mat-card>
  <mat-card class="dashboard-card tile-nachlieferung" matRipple routerLink="/nachlieferung"
            *ngIf="canAccessTile(['NACH'])">

    <mat-card-header class="jcc">
      <mat-card-title class="text-uppercase subheader">Nachlieferung</mat-card-title>
    </mat-card-header>
    <mat-card-content class="d-flex text-large jcc my-m">
      <div class="d-flex jcc icon icon-nachlieferung">&nbsp;</div>
    </mat-card-content>
  </mat-card>
  <mat-card class="dashboard-card tile-kontakt" matRipple routerLink="/kontakt"
            *ngIf="canAccessTile(['CONTACT_PERSON'])">

    <mat-card-header class="jcc">
      <mat-card-title class="text-uppercase subheader">Kontakt</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="d-flex jcc icon icon-kontakt">&nbsp;</div>
    </mat-card-content>
  </mat-card>
  <mat-card class="dashboard-card tile-aktuelle" matRipple routerLink="/page/aktuelle-informationen" >
    <mat-card-header class="jcc">
      <mat-card-title class="text-uppercase text-center subheader">Aktuelle Informationen</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="d-flex jcc icon icon-aktuelle-informationen">&nbsp;</div>
    </mat-card-content>
  </mat-card>

  <mat-card class="dashboard-card tile-covid-19" matRipple routerLink="/page/covid-19-informationen" >
    <mat-card-header class="jcc">
      <mat-card-title class="text-uppercase text-center subheader">Covid 19 <wbr>Informationen</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="d-flex jcc icon icon-covid-19-informationen">&nbsp;</div>
    </mat-card-content>
  </mat-card>
  <mat-card class="dashboard-card tile-grippe-2024" matRipple routerLink="/vorbestellung/grippe2024" matBadge="NEU" matBadgeSize="large">
    <mat-card-header class="jcc">
      <mat-card-title class="text-uppercase text-center subheader">Vorbestellung Lagerware Grippe</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="d-flex jcc icon icon-grippe">&nbsp;</div>
    </mat-card-content>
  </mat-card>


  <mat-card class="dashboard-card tile-daten" matRipple routerLink="/stammdaten"
            *ngIf="canAccessTile(['GROUP_ACCOUNT_OWNER']) && !isAdmin()">



    <mat-card-header class="jcc">
      <mat-card-title class="text-uppercase subheader">Meine Daten</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="d-flex jcc icon icon-meine-daten">&nbsp;</div>
    </mat-card-content>
  </mat-card>

  <mat-card class="dashboard-card tile-hgf" matRipple routerLink="/page/hgf" >
    <mat-card-header class="jcc">
      <mat-card-title class="text-uppercase subheader text-center">Häufig gestellte Fragen</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="d-flex jcc icon icon-hgf">&nbsp;</div>
    </mat-card-content>
  </mat-card>
<!--    <mat-card class="dashboard-card tile-chargen" matRipple routerLink="/page/chargenrueckrufe" *ngIf="!(this.userService.getUserLoadingObservable() | async)">-->
<!--      <mat-card-header class="jcc">-->
<!--        <mat-card-title class="text-uppercase text-center subheader">Chargenrückrufe/<wbr>Sicherheitshinweise</mat-card-title>-->
<!--      </mat-card-header>-->
<!--      <mat-card-content>-->
<!--        <div class="d-flex jcc icon icon-chargen">&nbsp;</div>-->
<!--      </mat-card-content>-->
<!--    </mat-card>-->

  <mat-card class="dashboard-card tile-mitbenutzer" matRipple routerLink="/subusers"
            *ngIf="!(this.userService.getUserLoadingObservable() | async) && canAccessTile(['GROUP_ACCOUNT_OWNER'])  && !isAdmin()">


    <mat-card-header class="jcc">
      <mat-card-title class="text-uppercase subheader">Mitbenutzer</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="d-flex jcc icon icon-mitbenutzer">&nbsp;</div>
    </mat-card-content>
  </mat-card>

</div>
