import {ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {UserService} from "../../core/user/services/user.service";
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {InvoicesService} from "../../core/invoices/services/invoices.service";
import {DocTypesRequest} from "../../core/invoices/api/doc.types.request.model";
import {InvoicesRequest} from "../../core/invoices/api/invoices.request.model";
import {MatStepper} from '@angular/material/stepper';
import * as moment from 'moment';
import {Router} from '@angular/router';


export interface TableElement {
  name: string;
  position: number;
  price: string
  symbol: string;
  date: string;
}

const DUMMY_DATA: TableElement[] = [
  {position: 1, name: 'Lorem Ipsum Dolor', price: '22,33 €', symbol: 'Lorem ipsum dolor sit amet', date: '18.02.2016'},
  {
    position: 2,
    name: 'consetetur sadipscing elitr',
    price: '12,33 €',
    symbol: 'Lorem ipsum dolor sit amet',
    date: '17.05.2074'
  },
  {
    position: 3,
    name: 'At vero eos et accusam',
    price: '24,43 €',
    symbol: 'Lorem ipsum dolor sit amet',
    date: '27.02.2014'
  },
  {
    position: 4,
    name: 'sanctus est Lorem ipsum',
    price: '2,93 €',
    symbol: 'Lorem ipsum dolor sit amet',
    date: '27.05.1014'
  },
  {position: 5, name: 'sed diam voluptua', price: '42,12 €', symbol: 'Lorem ipsum dolor sit amet', date: '27.04.2314'},
  {
    position: 6,
    name: 'no sea takimata sanctus',
    price: '52,43 €',
    symbol: 'Lorem ipsum dolor sit amet',
    date: '07.12.1914'
  },
];

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnDestroy {
  displayedColumns: string[] = ['invNr', 'type', 'idf', 'invDate', 'download'];
  private ngUnsubscribe$ = new Subject<boolean>();
  public idfs: any[] = [];
  public loading = true;
  public maxDate = moment();
  public minDate = moment().subtract(1, 'years');
  public noIdf = true;
  public docs: any[] = [];

  searchForm = this.fb.group({
    idf: ['', [
      Validators.required
    ]],
    docType: ['', [
      Validators.required
    ]],
    startDate: ['', [
      Validators.required
    ]],
    endDate: ['',
      [
        Validators.required
      ]],
    pzn: [''],
    docNr: [''],
    description: ['']
  });

  quickSearch = this.fb.group({
    docNr: ['']
  })

  quickPZNSearch = this.fb.group({
    pzn: ['']
  })

  dateRange = this.fb.group({
    start: [''],
    end: ['']
  });

  idfSelect = this.fb.group({
    idf: ['', [
      Validators.required
    ]]
  })

  isEditable = true;
  stepped = false;
  selected: any;
  showTable = false;
  docTypes: any[] = [];
  docTypeLoading = true;
  public months: any[] = [];
  public documents: any [] = [];
  public selectedType: any = null;
  private daySelect: string[] = [
    'INVOICE',
    'BATCH_ADVICE'
  ];
  public isAdmin = false;
  public now = moment();
  public selectedIdf: string = '';
  showPzn: boolean = false;
  showDocNr: boolean = false;
  private pzns: any[] = ['INVOICE', 'CREDIT_NOTE'];
  private docNrs: any[] = ['INVOICE', 'BATCH_ADVICE'];
  selectedMonth:any = 0;
  quick = false;


  constructor(private fb: FormBuilder,
              private userService: UserService,
              private invoicesService: InvoicesService,
              private router: Router,
              private cdr: ChangeDetectorRef) {

    this.userService.getUserLoadingObservable().pipe(takeUntil(this.ngUnsubscribe$)).subscribe((load: any) => {
      if (!load) {
        this.isAdmin = this.userService.isAdmin();
      }
    });

    this.userService.getIdfsObservable('invoice')?.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((response: any) => {
      if (response != undefined && response.hasOwnProperty('idfs')) {
        this.idfs = response.idfs;
        console.log(this.idfs);
        if(this.idfs.length === 1) {
          this.idfSelect.patchValue({idf: this.idfs[0].idf});
          this.noIdf = false;
          this.getDocTypes(this.idfs[0].idf);
        }
      }
    });

    let now: Date = new Date();
    let prevYear: Date = new Date();
    const month: number = now.getMonth();
    prevYear.setMonth(-(month + 1));
    let j = 0;
    for (let i = 0; i < 12; i++) {
      if (month - i > -1) {
        this.months.push(now.setMonth(month - i));
      } else {
        this.months.push(prevYear.setMonth(11 - j));
        j++;
      }
    }
  }

  getDocTypes(idf: string) {
    this.documents = [];
    this.showTable = false;
    this.searchForm.patchValue({idf: idf});
    if (idf) {
      this.noIdf = false;
      this.selectedIdf = idf;
    }
    this.invoicesService.getUserDocumentTypes(new DocTypesRequest({
      idf: idf,
      dc: [21]
    }))?.pipe(takeUntil(this.ngUnsubscribe$))
    .subscribe((response: Map<string, any>) => {
      if (response.size > 0 && response.has(idf)) {
        const docTypeMap: any = response.get(idf);
        this.docTypes = [];
        this.showTable = false;
        for (const key of Object.keys(docTypeMap)) {
          this.docTypes.push(docTypeMap[key].document_type);
        }
        this.docTypeLoading = false;
      }
    });
  }

  getFirstOfMonth(month: any) {
    const firstDay = moment(month).startOf('month').format("YYYY-MM-DD");
    return firstDay;
  }

  getDocTypesForCustomer($event: any) {
    this.selectedIdf = this.formatIdf($event.target.value);
    $event.stopPropagation();

    this.getDocTypes(this.selectedIdf);
  }

  search($event: any) {
    $event.stopPropagation();
    $event.preventDefault();
    let invoicesRequest = new InvoicesRequest({});

    if (this.searchForm.valid) {
      let formValues: any = this.searchForm.value;
      formValues['branch'] = 21;
      invoicesRequest = new InvoicesRequest(formValues);
      this.searchDocuments(invoicesRequest);
    } else {
      return
    }
  }

  setDateRange(month: any, step?: MatStepper) {
    let endDate: string = '';
    let startDate: string = '';

    if (month === 'all') {
      endDate = moment().endOf('month').format('YYYY-MM-DD');
      startDate = moment().subtract(1, 'year').format('YYYY-MM-DD');
    } else {
      startDate = moment(month).startOf('month').format('YYYY-MM-DD');
      endDate = moment(month).endOf('month').format('YYYY-MM-DD');
    }
    this.selectedMonth = month;

    this.searchForm.patchValue({startDate: startDate, endDate: endDate})
    if (this.searchForm.valid) {
      let formValues: any = this.searchForm.value;
      formValues['branch'] = 21;
      let invoicesRequest = new InvoicesRequest(formValues);
      this.searchDocuments(invoicesRequest,step);
    }
  }

  findDocType(docType: string) {
    if (this.docTypes.length > 0) {
      return this.docTypes.find((type: any) => type.attribute === docType);
    }
  }

  show12Months() {
    const docType = this.selectedType ? this.selectedType : this.searchForm.get('docType')?.value;
    if (docType) {
      return this.daySelect.indexOf(docType) < 0;
    }
    return true;
  }

  setToday() {
    this.selectedMonth = 0;
    const now = moment().format('YYYY-MM-DD')
    this.searchForm.patchValue({startDate: now, endDate: now});
  }

  setLastWeek() {
    this.selectedMonth = 0;
    const now = moment();
    const end = now.format('YYYY-MM-DD');
    const start = now.subtract(7, 'day').format('YYYY-MM-DD');
    this.searchForm.patchValue({startDate: start, endDate: end});
  }

  setLastMonth() {
    this.selectedMonth = 0;
    const now = moment();
    const end = now.format('YYYY-MM-DD');
    const start = now.subtract(1, 'month').format('YYYY-MM-DD');
    this.searchForm.patchValue({startDate: start, endDate: end});
  }

  setStartDate($event: any) {
    this.selectedMonth = 0;
    const select = $event.value;
    const start = moment(select).format('YYYY-MM-DD');

    this.searchForm.patchValue({startDate: start});

  }

  setEndDate($event: any) {
    this.selectedMonth = 0;
    const select = $event.value;
    const end = moment(select).format('YYYY-MM-DD');

    this.searchForm.patchValue({endDate: end});
  }

  setDocType($event: any) {
    this.selectedType = $event.value;
    this.showDocNr = false;
    this.showPzn = false;
    this.searchForm.get('pzn')?.disable();
    this.searchForm.get('docNr')?.disable();

    if (this.docNrs.indexOf(this.selectedType) > -1) {
      this.showDocNr = true;
      this.searchForm.get('docNr')?.enable();
      if(!this.searchForm.get('startDate')?.value) {
        this.searchForm.patchValue({'startDate': moment().format('YYYY-MM-DD'), 'endDate': moment().subtract(1, 'months').format('YYYY-MM-DD')})
      }
    }

    if (this.pzns.indexOf(this.selectedType) > -1) {
      this.showPzn = true;
      this.searchForm.get('pzn')?.enable();
      if(!this.searchForm.get('startDate')?.value) {
        this.searchForm.patchValue({'startDate': moment().format('YYYY-MM-DD'), 'endDate': moment().subtract(1, 'months').format('YYYY-MM-DD')})
      }
    }
  }

  formatIdf(idf: string) {
    return ('0000000' + idf).slice(-7)
  }

  doQuickSearch(stepper: MatStepper) {
    const idf = this.searchForm.get("idf")?.value;
    this.quick = true;
    if (!idf) {
      this.showTable = false;
      return;
    }
    if (!this.quickSearch.valid) {
      return;
    }
    const now = moment();
    const endDate = now.format('YYYY-MM-DD');
    const startDate = now.subtract(1, 'year').format('YYYY-MM-DD');
    let request = new InvoicesRequest({
      idf: idf,
      branch: 21,
      startDate: startDate,
      endDate: endDate,
      docType: "ALL",
      docNr: this.quickSearch.get("docNr")?.value
    });
    this.searchDocuments(request, stepper);
  }

  searchDocuments(request: InvoicesRequest, step?: MatStepper) {
    this.documents = [];
    this.docs = [];
    this.showTable = false;

    this.invoicesService.searchDocuments(request).pipe(takeUntil(this.ngUnsubscribe$))
    .subscribe((response: any) => {
      this.docs = [];
      this.documents = [];

      if (response !== undefined) {
        response.map((document: { [index: string]: any }) => {
          let doc = Object.assign({}, document);
          if (document['type']) {
            doc['type'] = this.findDocType(document['type']);
              this.documents.push(doc);

          } else {
            doc['type'] = this.findDocType(request.docType);
              this.documents.push(doc);
          }

        });
        this.docs = this.documents;
        if (step) {
          this.stepped = true;
          step.next();
        }
        this.showTable = true;
      }
    });
  }


  resetSteps() {
    this.quick = false;
    // this.documents = [];
    // this.router.navigateByUrl('/belege', {skipLocationChange: true}).then(() => {
    //   this.router.navigate(['belege']);
    // });
    window.location.reload();
  }

  getMyDocTypes($event: any) {
    const idf = $event.value;
    this.getDocTypes(idf);
  }

  doQuickPZNSearch(stepper: any) {
    const idf = this.searchForm.get("idf")?.value;
    if (!idf) {
      this.showTable = false;
      return;
    }
    if (!this.quickSearch.valid) {
      return;
    }
    const now = moment();
    const endDate = now.format('YYYY-MM-DD');
    const startDate = now.subtract(1, 'year').format('YYYY-MM-DD');
    let request = new InvoicesRequest({
      idf: idf,
      branch: 21,
      startDate: startDate,
      endDate: endDate,
      docType: 'ALL',
      pzn: this.quickPZNSearch.get('pzn')?.value
    });
    this.searchDocuments(request, stepper);
  }


  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy(): void {
    this.ngUnsubscribe$.next(true);
    this.ngUnsubscribe$.complete();
  }
}
