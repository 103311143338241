<h1 class="title">Belege</h1>
<app-notifications></app-notifications>

<mat-stepper linear #stepper labelPosition="bottom">
  <mat-step label="IDF Auswahl">
    <div class="d-flex jcsb">
      <div>
        <form [formGroup]="idfSelect">
          <mat-radio-group
            aria-labelledby="IDF Auswahl"
            class="d-flex flex-column" *ngIf="!isAdmin && idfs.length > 1">
            <mat-radio-button value="{{idf.idf}}" *ngFor="let idf of idfs"
                              (change)="getMyDocTypes($event)"
                              [checked]="selectedIdf===idf.idf">{{idf.idf}}</mat-radio-button>
            <mat-error *ngIf="noIdf">Bitte wählen Sie Ihre IDF</mat-error>
          </mat-radio-group>
          <mat-radio-group
            aria-labelledby="IDF Auswahl"
            class="d-flex flex-column" *ngIf="!isAdmin && idfs.length === 1">
            <mat-radio-button value="{{idf.idf}}" *ngFor="let idf of idfs"
                              (change)="getMyDocTypes($event)"
                              [checked]="idf.idf">{{idf.idf}}</mat-radio-button>
            <mat-error *ngIf="noIdf">Bitte wählen Sie Ihre IDF</mat-error>
          </mat-radio-group>
          <mat-form-field *ngIf="isAdmin" appearance="outline" class="mt-s">
            <mat-label>IDF-Nummer</mat-label>
            <input matInput (change)="getDocTypesForCustomer($event)" [value]="selectedIdf">
            <mat-error *ngIf="noIdf">Bitte schreiben Sie eine IDF</mat-error>
          </mat-form-field>
        </form>
      </div>
      <div class="d-flex flex-column">
        <form [formGroup]="quickSearch">
          <h3>Schnellsuche nach Belegnummer</h3>
          <mat-form-field appearance="outline" class="mt-s mr-s">
            <mat-label>Belegnummer</mat-label>
            <input matInput formControlName="docNr">
          </mat-form-field>
          <button mat-flat-button color="primary"
                  (click)="doQuickSearch(stepper); $event.stopPropagation(); $event.preventDefault()">Suchen
          </button>
        </form>
<!--        <form [formGroup]="quickPZNSearch">-->
<!--          <h3>Schnellsuche nach PZN Nummer</h3>-->
<!--          <mat-form-field appearance="outline" class="mt-s mr-s">-->
<!--            <mat-label>PZN nummer</mat-label>-->
<!--            <input matInput formControlName="pzn">-->
<!--          </mat-form-field>-->
<!--          <button mat-flat-button color="primary"-->
<!--                  (click)="doQuickPZNSearch(stepper); $event.stopPropagation(); $event.preventDefault()">Suchen-->
<!--          </button>-->
<!--        </form>-->
      </div>
    </div>
    <div class="my-m">
      <button mat-button matStepperNext type="button" [disabled]="noIdf">Weiter <span class="material-icons">arrow_forward</span>
      </button>
    </div>
  </mat-step>
  <form [formGroup]="searchForm" id="searchForm">
    <mat-step label="Belegart/Zeitraum" *ngIf="!quick">
      <div class="d-flex jcsb">
        <div class="doc-types">
          <mat-spinner *ngIf="docTypeLoading" [diameter]="50"></mat-spinner>
          <mat-radio-group
            aria-labelledby="Belegartliste"
            class="d-flex flex-column"
            formControlName="docType"
            *ngIf="!docTypeLoading">
            <mat-radio-button value="{{docType.attribute}}"
                              *ngFor="let docType of docTypes"
                              (change)="setDocType($event)">{{docType.value}}</mat-radio-button>
          </mat-radio-group>
          <mat-error *ngIf="!selectedType">Bitte wählen Sie ein Belegtyp</mat-error>
        </div>
        <div class="advanced-search d-flex flex-column" *ngIf="showDocNr || showPzn">
          <h3>Erweiterte Suche</h3>
          <mat-form-field appearance="outline" class="mt-s" *ngIf="showDocNr">
            <mat-label>Belegnummer</mat-label>
            <input matInput formControlName="docNr">
          </mat-form-field>
          <mat-form-field appearance="outline" class="mt-s" *ngIf="showPzn">
            <mat-label>PZN Nummer (bis einschließlich Vortag)</mat-label>
            <input matInput formControlName="pzn">
          </mat-form-field>
        </div>
        <div class="time w-40">
          <div class="d-flex flex-column jcc by-months mb-m">
            <h3>Belege nach Monat</h3>
            <mat-card class="calendar-card ">
              <mat-card-content class="months">
                <button mat-flat-button type="button" *ngFor="let month of months"
                        color = "{{(selectedMonth === month) ? 'primary' : ''}}"
                        (click)="selectedMonth = month; setDateRange(month, stepper)"
                        class="mr-s mb-s">{{month | date: 'MMMM yyyy'}}</button>
              </mat-card-content>
            </mat-card>

            <button mat-stroked-button type="button"
                    (click)="selectedMonth = 'all'; setDateRange('all', stepper)"
                    color = "{{(selectedMonth === 'all') ? 'primary' : 'secondary'}}"
                    *ngIf="show12Months()"
                    class="mt-l">
              Alle Belege der letzte 12 Monate
            </button>
          </div>
          <div *ngIf="!show12Months()" class="w-100 d-flex flex-column">
            <div class="defined-dates">
              <h3>Belege nach vordefinierten Zeiträumen</h3>
              <div class="d-flex jcsb mb-m">
                <button class="days" mat-stroked-button color="secondary"
                        (click)="setToday(); $event.stopPropagation(); $event.preventDefault()">Heute
                </button>
                <button class="days" mat-stroked-button color="secondary"
                        (click)="setLastWeek(); $event.stopPropagation(); $event.preventDefault()">Letzte 7 Tage
                </button>
                <button class="days" mat-stroked-button color="secondary"
                        (click)="setLastMonth(); $event.stopPropagation(); $event.preventDefault()">Letzte 30 Tage
                </button>
              </div>
            </div>
            <div>
              <h3>Belege nach genauen Zeitraum</h3>
              <div class="d-flex flex-column">
                <mat-form-field class="mr-s">
                  <input matInput [matDatepicker]="picker1" placeholder="Start Date" formControlName="startDate"
                         (dateChange)="setStartDate($event)">
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
                <mat-form-field>
                  <input matInput [matDatepicker]="picker2" placeholder="End Date" (dateChange)="setEndDate($event)"
                         formControlName="endDate">
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>
          <mat-error *ngIf="!(searchForm.get('startDate')?.value || searchForm.get('endDate')?.value)">Bitte wählen Sie den Zeitraum für die Suche aus.</mat-error>
        </div>
      </div>
      <div class="my-m">
        <button mat-button matStepperPrevious type="button" (click)="$event.stopPropagation(); $event.preventDefault()">
          <span class="material-icons">arrow_back</span> Zurück
        </button>
        <button mat-button matStepperNext type="button" (click)="search($event);showTable = true"
                [disabled]="!this.searchForm.valid">Weiter <span class="material-icons">arrow_forward</span></button>
      </div>
    </mat-step>
  </form>
  <mat-step label="Ergebnisse zu Ihrer Belegsuche">
    <div class="my-m">
      <button mat-flat-button matStepperPrevious type="button" (click)="showTable = false; selectedMonth=0;" class="mr-m mb-m"><span class="material-icons">arrow_back</span>
          Zurück
      </button>
      <button mat-flat-button
              (click)="stepper.reset(); resetSteps();$event.stopPropagation(); $event.preventDefault(); documents = []; docs = []" type="button"
              class="mb-m">
        Reset <span class="material-icons">restart_alt</span>
      </button>
      <app-invoices-results [documents]="docs"></app-invoices-results>
    </div>
  </mat-step>
</mat-stepper>
